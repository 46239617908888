import mixpanel from "mixpanel-browser"

export function initMixpanel () {
  const mixpanelToken = import.meta.env.APP_MIXPANEL_TOKEN

  mixpanel.init(mixpanelToken, {
    debug: import.meta.env.DEV,
    track_pageview: true,
    persistence: "localStorage"
  })
}
