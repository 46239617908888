import type { AxiosError, InternalAxiosRequestConfig } from "axios"

export function request (config: InternalAxiosRequestConfig) {
  const access_token = sessionStorage.getItem("access_token")

  if (access_token) config.headers.setAuthorization(`Bearer ${access_token}`)

  return config
}

export function response (error: AxiosError) {
  if (error?.response?.status === 401) sessionStorage.removeItem("access_token")

  return Promise.reject(error)
}
