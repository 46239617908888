<script setup lang="ts">
import { cx } from "cva"
import { AppHeader } from "widgets/app-header"
import { Footer } from "./footer"

const classes = {
  wrapper: cx([
    "flex",
    "flex-col",
    "justify-between",
    "max-h-[calc(100vh-128px)]",
    "md:max-h-screen",
    "h-screen",
    "text-secondary"
  ]),
  content: cx([
    "container",
    "max-w-[432px]",
    "mx-auto",
    "py-6"
  ])
}
</script>

<template>
  <div :class="classes.wrapper">
    <AppHeader />

    <div :class="classes.content">
      <slot />
    </div>

    <Footer />
  </div>
</template>
