import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router"
import { AccountLayout, AuthLayout, BaseLayout } from "shared/ui"
import { useAuth, useSign, useSpinner } from "stores"
import { ROUTE_NAMES } from "shared/constants"
import { initMixpanel } from "shared/lib"

const HomePage = () => import("./home")
const LoginPage = () => import("./login")
const NotFoundPage = () => import("./not-found")
const SignPage = () => import("./sign")
const RefundPage = () => import("./refund")
const ClaimPage = () => import("./claim")

export const routes: RouteRecordRaw[] = [
  {
    component: HomePage,
    meta: {
      layout: AccountLayout,
      requiresAuth: true
    },
    name: ROUTE_NAMES.HOME,
    path: "/account"
  },
  {
    component: LoginPage,
    meta: { layout: AuthLayout },
    name: ROUTE_NAMES.LOGIN,
    path: "/login"
  },
  {
    component: RefundPage,
    meta: { layout: AccountLayout },
    name: ROUTE_NAMES.REFUND,
    path: "/refund"
  },
  {
    component: SignPage,
    meta: { layout: BaseLayout },
    name: ROUTE_NAMES.SIGN,
    path: "/s/:id"
  },
  {
    component: NotFoundPage,
    name: ROUTE_NAMES.NOT_FOUND,
    path: "/:pathMatch(.*)*"
  },
  {
    component: ClaimPage,
    meta: { layout: AccountLayout },
    name: ROUTE_NAMES.CLAIM,
    path: "/claim"
  }
]

const history = createWebHistory()

export const router = createRouter({ history, routes })

declare function ym(_counterId: number, _action: string, _goalName: string, _params: { [key: string]: string; }): void;

router.beforeEach(async (to, from, next) => {
  const spinner = useSpinner()
  spinner.show()

  initMixpanel()

  const goalCounterId = import.meta.env.APP_YANDEX_METRICS_COUNTER_ID

  ym(goalCounterId, "hit", to.path, {
    referrer: from.path
  })

  const auth = useAuth()

  if (sessionStorage.getItem("access_token")) await auth.getSubscription()

  const subscriptionSigningShortId = to.params?.id
  const isSignPageWithCommissionAgreementId = to.name === ROUTE_NAMES.SIGN && subscriptionSigningShortId && typeof subscriptionSigningShortId === "string"
  if (isSignPageWithCommissionAgreementId) {
    const sign = useSign()
    sign.subscriptionSigningShortId = subscriptionSigningShortId
    try {
      await sign.getCommissionAgreement()
      await sign.logIp()
      await sign.sendCommissionAgreementSignCode()
      next()
    } catch {
      next({ name: ROUTE_NAMES.NOT_FOUND })
    }
  } else if (to.name === ROUTE_NAMES.REFUND || to.name === ROUTE_NAMES.CLAIM) {
    next()
  } else if (!auth.isAuthorized && to.name !== ROUTE_NAMES.LOGIN) {
    next({ name: ROUTE_NAMES.LOGIN })
  } else if (auth.isAuthorized && to.name === ROUTE_NAMES.LOGIN) {
    next({ name: ROUTE_NAMES.HOME })
  } else {
    next()
  }
})

router.afterEach((_to, _from) => {
  const spinner = useSpinner()
  spinner.hide()
})
