<script setup lang="ts">
import { cx } from "cva"
import { NewButton } from "shared/ui"

const today = new Date()
const year = today.getFullYear()

const politicsUrl = "https://pedant.market/politics"

const classes = {
  footer: cx([
    "container",
    "flex",
    "flex-col",
    "text-center",
    "pb-9",
    "text-[14px]/[18px]"
  ])
}
</script>

<template>
  <footer :class="classes.footer">
    <span>© {{ year }} Pedant.Market</span>

    <p>
      <span>При входе в личный кабинет вы принимаете </span>
      <NewButton
        type="a"
        intent="link"
        underline="on"
        class="!w-auto !inline-block"
        :href="politicsUrl"
        target="_blank"
      >
        политике по обработке персональных данных
      </NewButton>
      <span> в ООО «Премиум маркет»</span>
    </p>
  </footer>
</template>
