import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"

export const useSpinner = defineStore("spinner", () => {
  const isLoading = ref<boolean>(false)

  const show = () => isLoading.value = true
  const hide = () => isLoading.value = false

  return {
    isLoading,
    show,
    hide
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSpinner, import.meta.hot))
}
