<script setup lang="ts">
import { useRoute } from "vue-router"
import { pedantMarketApi, request, response } from "shared/api"
import "./styles/index.css"
import { useSpinner } from "stores"
import { Spinner } from "shared/ui"

const route = useRoute()

pedantMarketApi.interceptors.request.use(request)
pedantMarketApi.interceptors.response.use(null, response)

const spinner = useSpinner()
</script>

<template>
  <component :is="spinner.isLoading ? Spinner : (route.meta.layout ?? 'div')">
    <router-view />
  </component>
</template>
