const footer = [
  "container",
  "pb-8",
  "mt-auto",
  "text-[10px]/[120%]",
  "md:text-[16px]/[20px]",
  "text-text-03-white"
]

export default {
  footer
}
