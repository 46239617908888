<script setup lang='ts'>
import { useRouter } from "vue-router"
import { ButtonProps, button } from "./classes"
import { computed } from "vue"

interface Props {
  type?: string, // input | a | router-link | button
  intent?: ButtonProps["intent"],
  size?: ButtonProps["size"],
  underline?: ButtonProps["underline"],
  title?: string,
  url?: string | { name: string }, // for router-link
  inputType?: string, // submit; for input
  value?: string, // for input
  href?: string, // for link
  target?: string, // for link
  ariaLabel?: string // for link
}

const props = withDefaults(
  defineProps<Props>(),
  {
    type: "button",
    intent: "default",
    size: "default",
    underline: "off",
    title: "",
    url: "",
    inputType: "",
    value: "",
    href: "",
    target: "",
    ariaLabel: ""
  }
)

const router = useRouter()

const finalHref = computed(() => {
  if (props.type === "router-link") {
    if (typeof props.url === "object" && props.url.name) {
      const route = router.resolve({
        name: props.url.name
      })

      return route.href
    }

    return props.url
  }

  return props.href
})
</script>

<template>
  <component
    :is="type"
    :class="button({ intent, size, underline })"
    :title="title ? title : null"
    :to="url ? url : null"
    :type="inputType ? inputType : null"
    :value="value ? value : null"
    :href="finalHref ? finalHref : null"
    :target="target ? target : null"
    :aria-label="ariaLabel ? ariaLabel : null"
  >
    <slot />
  </component>
</template>
