import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"

import api from "shared/api"

export const usePhoneVerificationStore = defineStore("phone-verification", () => {
  const resendTimer = ref<number>(0)
  const resendEnabled = ref<boolean>(true)
  const phone = ref<number | null> (null)
  const code = ref<string | null >(null)
  const isVerificationFailed = ref<boolean | null>(null)
  const isLoading = ref<boolean>(false)

  const setPhone = (newPhone: number) => phone.value = newPhone

  const sendCode = async () => {
    if (!phone.value) return

    const response = await api.phoneVerification.sendCode(phone.value)

    if (response.status === 200) {
      // Verification code sent successfully
      resendTimer.value = 60
      resendEnabled.value = false
      const countdownInterval = setInterval(() => {
        if (resendTimer.value) {
          resendTimer.value -= 1
        } else {
          clearInterval(countdownInterval)
          resendEnabled.value = true
        }
      }, 1000)
    }
  }

  const verifyCode = async(code: string) => {
    if (!phone.value) return

    isLoading.value = true

    const request = api.phoneVerification.verifyCode(phone.value, code)

    await request
      .then(() => isVerificationFailed.value = false)
      .catch(() => isVerificationFailed.value = true)
      .finally(() => isLoading.value = false)
  }

  return {
    resendTimer,
    resendEnabled,
    code,
    isVerificationFailed,
    isLoading,
    sendCode,
    verifyCode,
    phone,
    setPhone
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePhoneVerificationStore, import.meta.hot))
}
