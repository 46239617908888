import type { ComponentPublicInstance } from "vue"
import { seq } from "shared/api"

export interface SeqLogParams {
  component: ComponentPublicInstance | null;
  info: string;
  level: "Error" | "Warning" | "Info" | "Debug";
  message: unknown;
}

export function log ({ component, info, level, message }: SeqLogParams) {
  const messageText = message ? message : "no message"

  seq.post("/events/raw?clef", {
    AssemblyName: "PedantMarketCabinet",
    Component: component,
    Environment: import.meta.env.PROD ? "Production" : "Development",
    Location: window.location.href,
    "@l": level,
    "@m": messageText,
    "@t": new Date().toJSON(),
    "@x": info
  })
}
