import { pedantMarketApi } from "../clients"
import type { Brands } from "../types"

const getList = () => {
  return pedantMarketApi.get<Brands>("/brands")
}

export const brands = {
  getList
}
