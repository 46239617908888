const wrapper = [
  "bg-white",
  "flex",
  "flex-col",
  "gap-9",
  "md:gap-20",
  "min-h-screen"
]

const content = "container"

export default {
  wrapper,
  content
}
