import type { AxiosError } from "axios"
import { acceptHMRUpdate, defineStore } from "pinia"
import { computed, ref } from "vue"
import { router } from "pages"
import api, { FineStatus, type Subscription } from "shared/api"
import { ROUTE_NAMES } from "shared/constants"
import { log } from "shared/lib"
import { useSign } from "./sign"

export const useAuth = defineStore("auth", () => {
  const errors = ref<Record<string, string[]>>({})
  const isAuthorized = ref<boolean | null>(null)
  const isLoading = ref<boolean>(false)
  const isVerificationCodeSent = ref(false)
  const phoneNumber = ref("")
  const subscription = ref<NonNullable<Subscription["data"]> | null>(null)
  const verificationCode = ref("")
  const verificationErrors = ref<Record<string, string[]>>({})
  const fineStatus = ref<NonNullable<FineStatus> | null>(null)

  const signStore = useSign()

  const nextPayment = computed(() => {
    return subscription.value?.CommissionAgreementSubscriptionPayments?.find(
      payment => !payment.Completed
    )
  })

  function addBankCard () {
    if (subscription.value?.Id) {
      api.addBankCard(subscription.value.Id)
        .then(({ data }) => {
          if (!subscription.value) return

          const message = `The card has been successfully linked for ${subscription.value.Id} (subscription.Id)`
          log({
            component: null,
            info: "",
            level: "Info",
            message
          })

          window.location.href = data.paymentUrl
        })
        .catch((error: Error) => {
          if (!subscription.value) return

          const message = `The card has not been successfully linked for ${subscription.value.Id} (subscription.Id). Reason: ${error.message}`
          log({
            component: null,
            info: "",
            level: "Info",
            message}
          )
        })
    }
  }

  function cancelSubscription () {
    if (subscription.value) {
      api.cancelSubscription({
        agreementNumber: subscription.value.CommissionAgreement.Number,
        customerName: subscription.value.CustomerName,
        device: subscription.value.CommissionAgreement.FullModelName,
        customerPhone: subscription.value.CustomerPhone
      })
    }
  }

  async function getSubscription () {
    if (!subscription.value) {
      isLoading.value = true

      await api.getSubscription()
        .then(({ data: { data } }) => {
          isAuthorized.value = true
          subscription.value = data ?? null

          signStore.subscriptionSigningShortId = data.SubscriptionSigningShortId
          _getFineStatus()

          router.push({ name: ROUTE_NAMES.HOME })
        })
        .catch(() => {
          isAuthorized.value = false
          subscription.value = null
        })
        .finally(() => isLoading.value = false)
    }
  }

  function _getFineStatus () {
    isLoading.value = true

    if (!fineStatus.value && subscription.value) {
      return api.getFineStatus(subscription.value.Balance.planId)
        .then(({ data }) => {
          fineStatus.value = data
        })
        .catch(() => {
          fineStatus.value = null
        })
        .finally(() => isLoading.value = false)
    }
  }

  function logout () {
    isLoading.value = true

    api.logout()
      .then(() => {
        sessionStorage.removeItem("access_token")

        isAuthorized.value = false

        router.push({ name: ROUTE_NAMES.LOGIN })
      })
      .finally(() => {
        isLoading.value = false
        isVerificationCodeSent.value = false
        phoneNumber.value = ""
        subscription.value = null
        verificationCode.value = ""
      })
  }

  function getPaymentLink () {
    api.getPaymentLink({
      subscriptionAltPlanId: subscription.value?.AltPlanId ?? ""
    })
      .then(({ data }) => window.location.href = data.paymentLink )
  }

  function getPenaltyPaymentLink () {
    api.getPenaltyPaymentLink({
      subscriptionPlanId: subscription.value?.Id ?? "",
      penalties: subscription.value?.Balance.fines ?? []
    })
      .then(({ data }) => window.location.href = data.paymentUrl)
  }

  function getOverduePaymentLink() {
    api.getOverduePaymentLink({
      subscriptionAltPlanId: subscription.value?.AltPlanId ?? ""
    })
      .then(({ data }) => window.location.href = data.paymentLink )
  }

  function sendVerificationCode (isResend: boolean = false) {
    isLoading.value = true

    api.generateOtp(phoneNumber.value.replace(/\D/g, ""), isResend ? "sms" : "cascade")
      .then(({ status }) => {
        if (status === 200) {
          isVerificationCodeSent.value = true
        }
      })
      .catch(error => {
        if (error instanceof Error && "isAxiosError" in error) {
          const e = error as AxiosError<{ errors?: Record<string, string[]> }>

          errors.value = e.response?.data?.errors ?? {}
        }
      })
      .finally(() => isLoading.value = false)
  }

  function verifyCode () {
    isLoading.value = true

    api.verifyOtp({
      otp_code: verificationCode.value,
      phone_number: phoneNumber.value.replace(/\D/g, "")
    })
      .then(({ data }) => {

        sessionStorage.setItem("access_token", data.access_token)
      })
      .then(getSubscription)
      .catch(error => {
        if (error instanceof Error && "isAxiosError" in error) {
          const e = error as AxiosError<{ errors?: Record<string, string[]> }>

          verificationErrors.value = e.response?.data?.errors ?? {}
        }
      })
      .finally(() => isLoading.value = false)
  }

  function removeBankCard () {
    if (subscription.value?.CardId) {
      api.removeBankCard(subscription.value.CardId)
        .then(() => {
          if (!subscription.value) return

          const message = `The card has been successfully unlinked for ${subscription.value.Id} (subscription.Id)`
          log({
            component: null,
            info: "",
            level: "Info",
            message
          })

          subscription.value.CardId = ""
          subscription.value.Pan = ""
        })
        .catch((error: Error) => {
          if (!subscription.value) return

          const message = `The card has not been successfully unlinked for ${subscription.value.Id} (subscription.Id). Reason: ${error.message}`
          log({
            component: null,
            info: "",
            level: "Info",
            message
          })
        })
    }
  }

  function submit () {
    if (isVerificationCodeSent.value) {
      verifyCode()
    } else {
      sendVerificationCode()
    }
  }

  return {
    addBankCard,
    cancelSubscription,
    errors,
    getPaymentLink,
    getPenaltyPaymentLink,
    getOverduePaymentLink,
    getSubscription,
    isAuthorized,
    isLoading,
    isVerificationCodeSent,
    logout,
    nextPayment,
    phoneNumber,
    removeBankCard,
    submit,
    subscription,
    verificationCode,
    verificationErrors,
    fineStatus,
    sendVerificationCode
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuth, import.meta.hot))
}
