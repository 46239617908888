<script setup lang="ts">
import { cx } from "cva"
import { AppHeader } from "widgets/app-header"
import { Modals } from "widgets/modals"

const wrapper = cx([
  "flex",
  "flex-col",
  "gap-9",
  "h-screen",
  "text-secondary"
])
</script>

<template>
  <div :class="wrapper">
    <AppHeader />

    <slot />

    <Modals />
  </div>
</template>
