import _ from "lodash"

export function splitToChunks<T>(array: T[], parts: number) {
  const arrayCopy = _.cloneDeep(array)
  const result = [] as T[][]
  for(let i = parts; i > 0; i--) {
    result.push(arrayCopy.splice(0, Math.ceil(arrayCopy.length / i)))
  }
  return result
}

export function openPdfFileInNewTab(blob: Blob) {
  const file = new Blob([blob], { type: "application/pdf" })
  window.location.href = URL.createObjectURL(file)
}

// solution from https://stackoverflow.com/a/52829183
export function downloadPdfFile(blob: Blob, fileName: string) {
  const file = new Blob([blob], { type: "application/pdf" })
  const link = document.createElement("a")
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(file)
  link.download = fileName
  // some browser needs the anchor to be in the doc
  document.body.append(link)
  link.click()
  link.remove()
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000)
}

export function formatePhone(phone: string) { // phone type: "79111111111"
  // Извлечем части номера
  const countryCode = phone[0]
  const areaCode = phone.slice(1, 4)
  const firstPart = phone.slice(4, 7)
  const secondPart = phone.slice(7, 9)
  const thirdPart = phone.slice(9, 11)

  // Возвратим номер в формате +7 (###) ###-##-##
  return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}-${thirdPart}`
}

export const extractPhone = (phone: string) => Number(phone.replace(/\D+/g, ""))
