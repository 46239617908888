<script setup lang="ts">
import classes from "./classes"

const today = new Date()
const year = today.getFullYear()
</script>

<template>
  <footer :class="classes.footer">
    <span>© {{ year }} Pedant.Market</span>
  </footer>
</template>
