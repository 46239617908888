const header = [
  "bg-primary-blue-01",
  "py-3",
  "md:py-[21px]"
]

const inner = [
  "container"
]

export default {
  header,
  inner
}
