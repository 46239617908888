import { pedantMarketApi } from "../clients"

function sendCode(phoneNumber: number) {
  return pedantMarketApi.post("/send-code", { phone_number: phoneNumber })
}

function verifyCode(phoneNumber: number, code: string) {
  return pedantMarketApi.post("/verificate-code", { phone_number: phoneNumber, otp_code: code })
}

export const phoneVerification = {
  sendCode,
  verifyCode
}
