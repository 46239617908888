import { maskito } from "@maskito/vue"
import { createPinia } from "pinia"
import { createApp } from "vue"
import { createVfm } from "vue-final-modal"
import { App } from "app"
import { router } from "pages"
import { log } from "shared/lib"

const app = createApp(App)
const modals = createVfm()
const store = createPinia()

app.config.errorHandler = function (message, component, info) {
  log({ component, level: "Error", info, message })
}

app.config.warnHandler = function (message, component, info) {
  log({ component, level: "Warning", info, message })
}

app
  .directive("maskito", maskito)
  .use(modals)
  .use(router)
  .use(store)
  .mount("#app")
