import { acceptHMRUpdate, defineStore } from "pinia"
import { ref, computed } from "vue"
import api, { CommissionAgreement } from "shared/api"
import { downloadPdfFile } from "shared/lib"

export const useSign = defineStore("sign", () => {
  const isLoading = ref<boolean>(false)
  const errors = ref<string[]>([])
  const isVerificationCodeSent = ref(false)
  const isSignCommissionAgreement = ref(false)
  const commissionAgreement = ref<NonNullable<CommissionAgreement["data"]> | null>(null)
  const subscriptionSigningShortId = ref("")
  const verificationCode = ref("")

  const hasErrors = computed(() => !!errors.value.length)

  function sendCommissionAgreementSignCode() {
    isLoading.value = true

    return api.sendSignCommissionAgreementCode(subscriptionSigningShortId.value)
      .then(({ status }) => {
        if (status === 200) {
          isVerificationCodeSent.value = true
        }
      })
      .catch(error => {
        const message = error.response?.data?.message

        if (message) {
          if (message === "Документ уже подписан") {
            isSignCommissionAgreement.value = true
          } else if(message.startsWith("Повторная смс может быть отправлена через")) {
            errors.value[0] = ("Повторная смс не может быть отправлена в данный момент")
          } else {
            errors.value[0] = message
          }
        }
      })
      .finally(() => isLoading.value = false)
  }

  function getSignPdfDocument() {
    return api.getSignPdfDocument(subscriptionSigningShortId.value)
      .then((response) => {
        if (response.status === 200) {
          downloadPdfFile(response.data, "Заявление о присоединении к договору (оферте) аренды устройства.pdf")
        }
      })
  }

  function signCommissionAgreement() {
    isLoading.value = true

    return api.signCommissionAgreement(subscriptionSigningShortId.value, verificationCode.value)
      .then(({ status }) => {
        if (status === 200) {
          isSignCommissionAgreement.value = true
        }
      })
      .catch(error => {
        const message = error.response?.data?.message
        if (message) errors.value[0] = message
      })
      .finally(() => isLoading.value = false)
  }

  function getCommissionAgreement() {
    isLoading.value = true

    return api.getCommissionAgreement(subscriptionSigningShortId.value)
      .then(({ data: { data } }) => {
        commissionAgreement.value = data ?? null
      })
      .catch((error: Error) => {
        commissionAgreement.value = null
        throw error
      })
      .finally(() => isLoading.value = false)
  }

  function submit () {
    if (isVerificationCodeSent.value) {
      signCommissionAgreement()
    } else {
      sendCommissionAgreementSignCode()
    }
  }

  function logIp () {
    isLoading.value = true

    return api.logIp(subscriptionSigningShortId.value)
      .finally(() => isLoading.value = false)
  }

  return {
    errors,
    isLoading,
    commissionAgreement,
    subscriptionSigningShortId,
    isSignCommissionAgreement,
    isVerificationCodeSent,
    verificationCode,
    hasErrors,
    sendCommissionAgreementSignCode,
    signCommissionAgreement,
    submit,
    getSignPdfDocument,
    getCommissionAgreement,
    logIp
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSign, import.meta.hot))
}
