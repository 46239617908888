<script setup lang="ts">
import { cx } from "cva"
import { useAuth } from "stores"
import { computed } from "vue"

const classes = {
  header: "py-4",
  inner: cx([
    "container",
    "flex",
    "flex-row"
  ]),
  link: cx([
    "duration-300",
    "outline-none",
    "focus:ring-2",
    "hover:opacity-80",
    "ring-[#023E7F]"
  ]),
  logo: cx(["inline-block", "max-w-none"]),
  logoutButton: cx([
    "flex",
    "flex-nowrap",
    "flex-row",
    "font-bold",
    "gap-4",
    "items-center",
    "ml-auto"
  ])
}

const computedClasses = computed(() => {
  return auth.isAuthorized ? {
    header: "bg-white",
    inner: "px-4",
    link: ""
  } : {
    header: "",
    inner: "justify-center",
    link: "justify-center"
  }
})

const auth = useAuth()
</script>

<template>
  <header :class="[classes.header, computedClasses.header]">
    <div :class="[classes.inner, computedClasses.inner]">
      <a
        :class="[classes.link, computedClasses.link]"
        href="https://pedant.market/"
      >
        <img
          :class="classes.logo"
          alt="Pedant.market"
          :src="`/assets/images/logo${!auth.isAuthorized ? '-white' : ''}.svg`"
        >
      </a>

      <button
        v-if="auth.isAuthorized"
        :class="classes.logoutButton"
        @click="auth.logout"
      >
        <img
          alt="poweroff"
          class="h-[18px] text-[#023E7F] w-[18px]"
          src="/assets/icons/poweroff.svg"
        >

        Выйти
      </button>
    </div>
  </header>
</template>
