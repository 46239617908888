import { cx, cva } from "cva"

const primaryWrapper = cx([
  "relative",
  "inline-block",
  "after:absolute",
  "after:top-[calc(50%-6px)]",
  "after:right-5",
  "after:block",
  "after:w-2",
  "after:h-2",
  "after:border-r-[3px]",
  "after:border-b-[3px]",
  "after:border-solid",
  "after:border-base-gray-04",
  "after:rotate-45",
  "after:pointer-events-none"
])

const secondaryWrapper = cx([
  "relative",
  "after:content-['▼']",
  "after:absolute",
  "after:top-2/4",
  "after:right-[5px]",
  "after:-translate-y-2/4",
  "after:text-black",
  "after:pointer-events-none"
])

const wrapper = cva([], {
  variants: {
    intent: {
      ["primary"]: primaryWrapper,
      ["secondary"]: secondaryWrapper
    }
  }
})

const primarySelect = cx([
  "max-h-16",
  "w-full",
  "h-full",
  "py-[7px]",
  "pr-[50px]",
  "pl-5",
  "border-2",
  "border-solid",
  "border-base-gray-02",
  "rounded-lg",
  "text-black",
  "appearance-none",
  "focus-visible:outline-1",
  "focus-visible:outline-primary-blue-01"
])

const secondarySelect = cx([
  "relative",
  "appearance-none",
  "border-none",
  "bg-transparent",
  "w-full",
  "cursor-pointer",
  "pr-5",
  "text-[14px]/[21px]",
  "md:text-[16px]/[24px]",
  "text-right",
  "[text-align-last:right]",
  "focus:border-none",
  "focus:outline-none",
  "hover:border-none",
  "hover:outline-none"
])

const select = cva([], {
  variants: {
    intent: {
      ["primary"]: primarySelect,
      ["secondary"]: secondarySelect
    }
  }
})

const invalidSelect = "!border-accent-red-02"

const invalidText = [
  "text-[11px]/[120%]",
  "text-negative",
  "mt-2"
]

const icon = [
  "absolute",
  "right-[16px]",
  "top-[34px]",
  "-translate-y-1/2"
]

export default {
  wrapper,
  select,
  invalidSelect,
  invalidText,
  icon
}
