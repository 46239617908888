import axios from "axios"
import { pedantMarketApi } from "./clients"
import {
  type CommissionAgreement,
  type CancelSubscriptionParams,
  type GenerateOtpResult,
  type PaymentLinkParams,
  type PenaltyPaymentLinkParams,
  type PenaltyPaymentLinkResult,
  type Subscription,
  type VerifyOtpParams,
  FineStatus,
  RefundCreditParams,
  RefundCashParams,
  RefundParams,
  ClaimParams
} from "./types"

function addBankCard(subscriptionPlanId: string) {
  return pedantMarketApi.post<{
    paymentUrl: string
  }>("/subscriptions/add-card", { SubscriptionPlanId: subscriptionPlanId })
}

function cancelSubscription(params: CancelSubscriptionParams) {
  const content = [
    `<div><b>Номер договора: </b> ${params.agreementNumber}</div>`,
    `<div><b>Устройство: </b> ${params.device}</div>`,
    `<div><b>Телефон: </b> ${params.customerPhone}</div>`,
    `<div><b>Имя: </b> ${params.customerName}</div>`
  ].join("")

  return axios.post("https://backend.pedant.market/api/mail/send", {
    content,
    title: "Заявка на отмену подписки"
  })
}

function generateOtp(phoneNumber: string, mode: "sms" | "cascade") {
  return pedantMarketApi.post<
    GenerateOtpResult
  >(`/send-code/${mode}`, { phone_number: phoneNumber })
}

function getSubscription() {
  return pedantMarketApi.get<Subscription>("/subscriptions")
}

function getPaymentLink({
  subscriptionAltPlanId: altPlanId,
  ...params
}: PaymentLinkParams) {
  return pedantMarketApi.post<{
    paymentLink: string
  }>("/subscriptions/current-payment-link", {
    ...params,
    advancePayment: false,
    advanceNextUnpaidPayment: true,
    altPlanId,
    courtCosts: false,
    earlyCancel: false,
    withoutFine: true
  })
}

function getPenaltyPaymentLink({
  subscriptionPlanId: planId,
  ...params
}: PenaltyPaymentLinkParams) {
  return pedantMarketApi.post<
    PenaltyPaymentLinkResult
  >("/subscriptions/penalties-payment-link", { ...params, planId }, {
    headers: { "Content-Type": "application/json" }
  })
}

function getOverduePaymentLink({
  subscriptionAltPlanId: altPlanId,
  ...params
}: PaymentLinkParams) {
  return pedantMarketApi.post<{
    paymentLink: string
  }>("/subscriptions/current-payment-link", {
    ...params,
    courtCosts: false,
    withoutFine: true,
    advanceNextUnpaidPayment: false,
    advancePayment: false,
    earlyCancel: false,
    altPlanId
  })
}

function logout() {
  return pedantMarketApi.post<{ message: string }>("/logout")
}

function removeBankCard(cardId: string) {
  return pedantMarketApi.post("/subscriptions/remove-card", { CardId: cardId })
}

function verifyOtp(params: VerifyOtpParams) {
  return pedantMarketApi.post<{ access_token: string }>("/verify-otp", params)
}

function sendSignCommissionAgreementCode(subscriptionSigningShortId: string) {
  return pedantMarketApi.post("/subscriptions/signing/send-code", { id: subscriptionSigningShortId })
}

function signCommissionAgreement(subscriptionSigningShortId: string, code: string) {
  return pedantMarketApi.post("/subscriptions/signing/sign", { id: subscriptionSigningShortId, code })
}

function getSignPdfDocument(subscriptionSigningShortId: string) {
  return pedantMarketApi.post<Blob>("/subscriptions/signing/document-pdf", { id: subscriptionSigningShortId }, { responseType: "blob" })
}

function getCommissionAgreement(subscriptionSigningShortId: string) {
  return pedantMarketApi.get<CommissionAgreement>("/subscriptions/signing/" + subscriptionSigningShortId)
}

function logIp(subscriptionSigningShortId: string) {
  return pedantMarketApi.post("/subscriptions/signing/log-ip", { shortId: subscriptionSigningShortId })
}

function getFineStatus(subscriptionPlanId: string) {
  return pedantMarketApi.get<FineStatus>(`/subscriptions/auto-fine-remove/status/${subscriptionPlanId}`)
}

function refund(params: RefundParams, paymentParams: RefundCreditParams | RefundCashParams) {
  return pedantMarketApi.post("/email/send-refund-request", { ...params, ...paymentParams })
}

function sendClaim(params: ClaimParams) {
  return pedantMarketApi.post("/email/send-claim", { ...params })
}

import * as requests from "./requests"

const api = {
  addBankCard,
  cancelSubscription,
  generateOtp,
  getPaymentLink,
  getPenaltyPaymentLink,
  getOverduePaymentLink,
  getSubscription,
  logout,
  removeBankCard,
  verifyOtp,
  sendSignCommissionAgreementCode,
  signCommissionAgreement,
  getSignPdfDocument,
  getCommissionAgreement,
  logIp,
  getFineStatus,
  refund,
  sendClaim,
  ...requests
}

export * from "./clients"
export * from "./interceptors"
export * from "./types"

export default api
